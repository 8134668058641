import React, { createContext, useState, useMemo } from 'react'

const ModalContext = createContext(true)

export function ModalProvider({ children }) {
  const [modalData, setModalData] = useState('')
  const value = { modalData, setModalData }
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContext
